import axios from 'axios'

// const baseDomain = RumoConfigs.VUE_APP_API_URL || "http://localhost:8000"
const baseDomain = "https://api.ifoodreembolsoag.com/prd/nf"

const client = axios.create({
    baseURL: baseDomain,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Content-Language": `pt-BR`
    }
})

client.interceptors.request.use(
	config => {
		console.log(config)
		// Do something before request is sent
		const accessToken = localStorage.getItem('token')
		// eslint-disable-next-line no-param-reassign
		if (accessToken) config.headers.Authorization = `${accessToken}`
		return config
	},
	error => Promise.reject(error),
)

export default client