<template>
	<div id="app">
		<router-view id="app-router-view" />
	</div>
</template>

<script>
// @ is an alias to /src
import HeaderIFood from '@/components/Header.vue'
import FooterIFood from '@/components/Footer.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'Home',
	data(){
		return {
		}
	},
	components: {
		HeaderIFood,
		FooterIFood,
	},
	watch:{
		// uploadLinkStatus: function(n) {
		// 	if(n === 'pending') this.isLoading = true;
		// 	else this.isLoading = false;
		// },
	},
	computed: {
		// ...mapGetters({
		// 	uploadLinkStatus: 'uploadLinkStatus',
		// }),
	},
}
</script>

<style lang="scss">
	#app {
		overflow-x: hidden;
		width: 100vw;
	}
	#app-router-view {
		height: 100%;
	}
</style>
