<template>
    <footer>
        <div class="container p-0 m-0">
            <slot></slot>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style lang="scss" scoped>
footer {
    position: fixed;
    z-index: 7;
    bottom: 0;
    left: 0;
    right: 0;
    .container {
    }
}
</style>
