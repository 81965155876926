import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import vuescroll from 'vuescroll';
import Maska from 'maska'
import money from 'v-money'
import Vuelidate from 'vuelidate'
import VueToast from 'vue-toast-notification';
import VueCarousel from 'vue-carousel'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueTheMask from 'vue-the-mask'

Vue.config.productionTip = false

Vue.use(require('vue-moment'));


Vue.use(VueTheMask)

Vue.use(vuescroll, {});
Vue.use(Maska)
Vue.use(money, {
	precision: 4,
	masked: false
})
Vue.use(Vuelidate)

Vue.prototype.api = ''

Vue.use(VueToast);
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueCarousel)
Vue.use(VueLoading);

Vue.filter('toCurrency', function (value) {
    if (typeof value !== "number") {
        return value;
    }
    var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });
    return formatter.format(value);
});

Vue.filter('date', {
	read: function (val) {
		return formatDate(parseDate(val));
	},
	write: function (val, oldVal) {
		var d = parseDate(val);
		return d ? formatDate(d) : val
	}
});

Vue.component('product-counter', {
	props: ['value'],
	computed: {
	  count: {
		get() { return this.value },
		set(newValue) { this.$emit('input', newValue) }
	  }
	},
	template: `<div class="counter">

					<button v-on:click="count--" v-bind:disabled="count<1" class="btn btn-minus">
						<svg width="14" height="2" viewBox="0 0 14 2" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1 1H13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>					
					</button>

					<input v-model.number="count" class="form-control text-center" >

					<button v-on:click="count++" class="btn btn-plus">
						<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M8 1C8 0.447715 7.55228 0 7 0C6.44772 0 6 0.447715 6 1V6H1C0.447715 6 0 6.44772 0 7C0 7.55228 0.447715 8 1 8H6V13C6 13.5523 6.44772 14 7 14C7.55228 14 8 13.5523 8 13V8H13C13.5523 8 14 7.55228 14 7C14 6.44772 13.5523 6 13 6H8V1Z"/>
						</svg>					
					</button>
			</div>
		  `
  })

window.loadDriverJWT = async function (token, workerId) {

	try {
		await axios.get(
				`https://fleet-api.ifood.com.br/api/logistics/crm-identity/worker`, {
					headers: {
						Authorization: `Bearer ${token}`
					},
				}
			)
			.then((response) => {
				const {
					id,
					name,
					cpf
				} = response.data;
				localStorage.setItem("worker_id", workerId)
				localStorage.setItem("id", id);
				localStorage.setItem("name", name);
				localStorage.setItem("document", cpf);
				localStorage.setItem("token", token);
				localStorage.setItem("region", response.data.region.name);
				localStorage.setItem("levelid", response.data.level.name);

				new Vue({
					router,
					store,
					render: h => h(App)
				}).$mount('#app')

			}).catch( err =>{
				console.log(err)
			});
	} catch (err) {
		console.error(err);
	}
}

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')